import { render, staticRenderFns } from "./MainCampInfo2.vue?vue&type=template&id=cd1efcfe&scoped=true&"
import script from "./MainCampInfo2.vue?vue&type=script&lang=js&"
export * from "./MainCampInfo2.vue?vue&type=script&lang=js&"
import style0 from "./MainCampInfo2.vue?vue&type=style&index=0&id=cd1efcfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd1efcfe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VCol,VImg,VRow})
