<template>
    <v-row>
        <v-col class="hidden-sm-and-down">

        </v-col>
        <v-col cols="12" md="11">
            <!--<v-col cols="12" md="12">
                <h1 class="about-sub-title">객실</h1>
            </v-col>-->
            <div class="px-4">
                <div class="sub-title-text">
                    <span style="font-size:2.5rem;">객실 (펜션)</span>
                </div>
                <v-row class="my-10">
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col
                                    v-for="(item,n) in roomList"
                                    :key="n"
                                    class="d-flex child-flex"
                                    cols="12" sm="3">
                                <v-card
                                        outlined
                                        class="roomListCard"
                                        @click="btnClick(0, item)">
                                    <v-img
                                            :src="item.roomThumb"
                                            :lazy-src="item.roomThumb"
                                            aspect-ratio="1.7"
                                            class="grey lighten-2"
                                    ></v-img>

                                    <v-card-title class="text-h5" style="font-weight: bold;">
                                        {{item.roomNm}}
                                    </v-card-title>
                                    <v-card-subtitle>
                                        기준인원:{{item.stdNop}} / 최대인원:{{item.maxNop}} <br>
                                        {{item.roomForm}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>

            </div>
        </v-col>
        <v-col>

        </v-col>
    </v-row>

</template>

<script>
    export default {
        name: 'MainRoomInfo3',
        components: {
        },

        data: () => ({
            //swiper: null,
            roomList: [],

        }),
        created: function () {
            this.getList({roomGubun: 'R', useYn:"Y", delYn:"N"});
        },

        methods: {
            getList(val) {
                return this.$store.dispatch("room/getRoomList", val)
                    .then((resp) => {
                        this.roomList = resp.message;
                        setTimeout(() => {

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToCampInfo(item) {


                this.$store.commit("room/SAVE_ROOM_INFO", item);
                this.$router.push({path: "/place/roomList/roomInfo"});
            },
            btnClick(val, item) {
                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId+'&roomGubun='+item.roomGubun;
                /*switch (val) {
                    case 0 : {  // 상세정보

                        this.$store.commit("room/SAVE_ROOM_INFO", item);
                        this.$router.push({path: "/place/roomList/roomInfo"});
                        //this.$router.push({name: "RoomInfo", params: item});

                        break;
                    }

                }*/

            }
        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
