<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row id="0" ref="0">
            <v-col>
                <!--<main-img></main-img>-->
                <div class="" style="width: 100%;">
                    <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                시설안내
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                시설안내
                            </h1>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row id="01" style="min-height: 750px;">
                    <div id="1" ref="1"></div>
                    <v-col>
                        <main-camp-info></main-camp-info>
                    </v-col>
                </v-row>
                <v-row id="02" style="background-color: #eaeaea;min-height: 1100px;">
                    <div id="2" ref="2"></div>
                    <v-col>
                        <main-room-info3></main-room-info3>
                    </v-col>
                </v-row>
                <v-row id="03" style="min-height: 400px;">
                    <div id="3" ref="3"></div>
                    <v-col>
                        <main-fac-info></main-fac-info>
                    </v-col>
                </v-row>
                <v-row id="04" style="background-color: #eaeaea; min-height: 400px;">
                    <div id="4" ref="4"></div>
                    <v-col>
                        <main-vally-info></main-vally-info>
                    </v-col>
                </v-row>
                <v-row id="05" style="min-height: 400px;">
                    <div id="5" ref="3"></div>
                    <v-col>
                        <main-bench-info></main-bench-info>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import MainRoomInfo3 from '../../components/MainRoomInfo3'
    import MainCampInfo from '../../components/MainCampInfo2'
    import MainFacInfo from '../../components/MainFacInfo2'
    import MainVallyInfo from '../../components/MainVallyInfo2'
    import MainBenchInfo from '../../components/MainBenchInfo'
    import MainImg from '../../components/MainImg'

    export default {
        name: 'PlaceMain',
        components: {
            MainRoomInfo3,
            MainCampInfo,
            MainFacInfo,
            MainVallyInfo,
            MainBenchInfo,
            MainImg,
        },
        data: () => ({

        }),
        created: function () {
            // 맨위로

            this.$nextTick(() => {
                let pageId = this.$getParameterByName('pageId');

                if (pageId !== "") {
                    setTimeout(() => {
                        this.scrollMeTo(pageId);
                    }, 300);
                    //this.hash("/place/main", pageId);

                }
            })

        },

        methods: {
            hash(route, pageId) {
                location.href = route + '/#' + pageId;
            },
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;

                window.scrollTo(0, top);
            }

        },
        mounted() {

        },

    }
</script>

