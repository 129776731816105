<template>
    <v-row>
        <v-col class="hidden-sm-and-down">

        </v-col>
        <v-col cols="12" md="11">
            <div class="px-4">
                <div>
                    <div class="sub-title-text">
                        <span style="font-size:2.5rem;">
                            수영장
                        </span>
                        <p style="font-size:1.1rem;">
                            <span style="color:darkblue">1급수로 청정함을 마음껏 즐기실 수 있습니다.</span><br>
                            <span style="color:darkblue">자외선을 차단하는 차광막(지붕)이 있습니다.</span>
                        </p>
                    </div>
                </div>
                <v-row class="my-10">
                    <v-col cols="12" class="pa-0 hidden-sm-and-down">
                            <swiper class="swiper"
                                    :options="swiperOption2"
                                    ref="roomSwiper"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-2" :src="room.roomImgRoute"
                                             @click="goToRoomInfo(room)"/>
                                    </swiper-slide>
                                </template>
                                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                            </swiper>
                    </v-col>
                    <v-col cols="12" class="pa-0 hidden-md-and-up">
                            <swiper class="swiper"
                                    :options="swiperOption"
                                    ref="roomSwiper1"
                            >
                                <template v-for="(room, i) in roomList">
                                    <swiper-slide>
                                        <img class="main-slide-img-4" :src="room.roomImgRoute"
                                             @click="goToRoomInfo(room)"/>
                                    </swiper-slide>
                                </template>
                            </swiper>
                    </v-col>
                </v-row>

            </div>
        </v-col>
        <v-col>

        </v-col>
    </v-row>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainFacInfo',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {

                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                loopAdditionalSlides: 3,
                spaceBetween: 10,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            this.getList({roomGubun: 'R'});

        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            },
            roomSwiper1() {
                return this.$refs.roomSwiper1;
            }
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("admin/getRoomImg", {roomId: 'PO'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.roomList = resp.message;
                            this.$nextTick(function () {
                                this.roomSwiper.$swiper.slideTo(5, 300);
                                this.roomSwiper1.$swiper.slideTo(5, 300);
                            })
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToRoomInfo(item) {
            }
        }

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
