<template>
    <v-row>
        <v-col class="hidden-sm-and-down">

        </v-col>
        <v-col cols="12" md="11">
            <!--<v-col cols="12" md="12">
                <h1 class="about-sub-title">캠핑장</h1>
            </v-col>-->
            <div class="px-4">
                <div class="sub-title-text">
                    <span style="font-size:2.5rem;">캠핑장</span>
                </div>

                <v-row class="my-10">
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col
                                    v-for="(item,n) in roomList"
                                    :key="n"
                                    class="d-flex child-flex"
                                    cols="12" sm="3">
                                <v-card
                                        outlined
                                        class="roomListCard"
                                        @click="goToInfo(item)">
                                    <v-img
                                            :src="item.roomThumb"
                                            :lazy-src="item.roomThumb"
                                            aspect-ratio="1.7"
                                            class="grey lighten-2"
                                    ></v-img>

                                    <v-card-title class="text-h5" style="font-weight: bold;">
                                        {{item.roomNm}}
                                    </v-card-title>
                                    <v-card-subtitle>
                                        기준인원:{{item.stdNop}} / 최대인원:{{item.maxNop}} <br>
                                        {{item.roomForm}}
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-col>
        <v-col>

        </v-col>
    </v-row>

</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    //import 'swiper/css/swiper.css'

    export default {
        name: 'MainRoomInfo2',
        components: {
            Swiper,
            SwiperSlide
        },

        data: () => ({
            //swiper: null,
            roomList: [],
            swiperOption: {
                centeredSlides: true,
                slidesPerView: 1.1,
                loop: true,
                loopAdditionalSlides:3,
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
            swiperOption2: {
                spaceBetween: 10,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },

                }
            },
        }),
        created: function () {
            this.getList({roomGubun: 'R'});
        },
        computed: {
            roomSwiper() {
                return this.$refs.roomSwiper;
            }
        },
        methods: {
            getList(val) {
                return this.$store.dispatch("room/getCampList", {})
                    .then((resp) => {
                        this.roomList = resp.message;
                        setTimeout(() => {

                            //this.roomSwiper.$swiper.slideTo(3, 500);

                            setTimeout(() => {

                            }, 300)
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            goToInfo(item) {

                location.href = "/place/roomList/roomInfo" + '?roomId=' + item.roomId+'&roomGubun='+item.roomGubun;
            },

        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
